import React, { useState, useContext, useEffect, useRef, } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import parse, { attributesToProps, domToReact } from 'html-react-parser';

import { InstarecContext } from 'COMPONENTS/InstarecContext';
import LoadingDots from 'GLOBAL/LoadingDots';
import handleViewport from 'HOOKS/handleViewport';
import usePrevious from 'HOOKS/usePrevious';
import { titleize, underscore } from 'HELPERS/text';

import ProductCard from './productcard';
import PreferabliAnalytics from 'UTILS/Vendor/analytics';
import LokaliseLanguage from 'UTILS/LokaliseLanguage';
import RecongizedTypes from 'HELPERS/recongizedtypes';


const GroupHeading = React.forwardRef(function GroupHeading(props, ref) {
	const context = useContext(InstarecContext);

	const setCustomText = (type, _lokalise) => {
		if (
			context.params.customText
			&& Object.keys(context.params.customText).includes('categories')
			&& Object.keys(context.params.customText.categories).length
		) {
			/// update to the recongized types array
			if(
				Object.keys(context.params.customText.categories).includes(type) 
				&& RecongizedTypes.All.includes(type)
			){
				let _customText = context.params.customText.categories[type];
				return (typeof _customText === 'string' && _customText.length) ? _customText : false;
			}
		}
		return false;
	};

	return (
		<div className={ clsx('irjs__results--heading') }>
			<h3>{setCustomText(underscore(props.nonLatin)) || LokaliseLanguage.getSlugTranslation({slug:`type.${underscore(props.nonLatin)}`})}</h3>
		</div>
	);
});

GroupHeading.propTypes = {
	className: PropTypes.string,
	style: PropTypes.shape(),
};
GroupHeading.defaultProps = {
	className: '',
	style: {},
};

const ResultGroupHeading = GroupHeading;

const CustomResultCard = ({ html, ...propParams }) => {
	const options = {
		replace: ({attribs, children, name, ...domNodeProps}) => {

			if(!domNodeProps.parent && children && children.length){
				const Tag = name;
				const props = attributesToProps(attribs);
				return (<Tag {...props} onClick={ propParams.onClick }>{domToReact(children, options)}</Tag>);
			}
			if (attribs && name === 'input') {
				const props = attributesToProps(attribs);
				return (<input { ...props } onChange={ () => {} } />);
			}
			if (attribs && name === 'select') {
				const props = attributesToProps(attribs);
				return (<select { ...props } onChange={ () => {} } />);
			}
		},
	};
	return parse(html, { trim: true, ...options });
};

const ResultsGrouping = (props) => {
	const {
		type, results, hasNextPage, nonLatinType, loadMore, forwardedRef, id,
	} = props;

	const prevResults = usePrevious(results);

	const context = useContext(InstarecContext);
	const [isLoadingMore, setLoading] = useState(false);
	const [showLoadMore, setShowLoadMore] = useState(hasNextPage);
	const [cards, setCards] = useState([]);
	const [waitForCallback, setWaitForCallback] = useState(typeof props.renderProductCards === 'function');
	const isCompact = Boolean(
		context.params.customStyling
			&& Object.keys(context.params.customStyling).length
			&& Object.keys(context.params.customStyling).includes('compact')
			&& context.params.customStyling.compact
	);

	const handleLoadMore = (e) => {
		e.preventDefault();
		setLoading(true);

		let node = e.target;
		if(node.nodeName.toLowerCase() !== 'button') node = e.target.parentNode;

		loadMore(node.dataset).then(() => {
			setLoading(false);
		}).catch((err) => {
			setLoading(false);
			// alert(`Unable to load more ${type} recommendations. Please try again later.`);
		});
	};

	useEffect(() => {

		if(results){

			const _doProducts = async () => {
			
				const _hasValidUrl = async (url) => (await fetch(url, {method: 'HEAD'})).ok;

				let newResults = results;
				if (typeof prevResults === 'object' && Object.values(prevResults).length) {
					newResults = newResults.filter((item) => !Object.values(prevResults).includes(item));
				}

				const allLookups = await Object.entries(newResults).reduce(async (acc, result) => {
					const joiner = await acc;
					const [objIdx, { product, lookups }] = result;
					const _valid =  (__ENV__ === 'production') ? await _hasValidUrl(lookups[0].landing_url) : Promise.resolve();
		            if (lookups[0].landing_url && typeof lookups[0].landing_url === 'string' && lookups[0].landing_url.length && _valid) joiner.push(lookups[0]);
					return joiner;
				}, Promise.resolve([]));

				props.renderProductCards(allLookups)
					.then((returnCards) => {
						const _cleanHtmlCards = returnCards.filter((_cardHtml) => (String(_cardHtml).length > 0));

						setCards([
							...new Set([
								...cards,
								..._cleanHtmlCards.map((cardHtml, idx) => {
									if (typeof cardHtml === 'string') {
										return { html: cardHtml, key: allLookups[idx].value, product: allLookups[idx] };
									}
									if (cardHtml.constructor === Promise) {
										/// do nothing
									}
								}),
							]),
						]);
						setWaitForCallback(false);
						if(typeof props.onComplete === 'function') props.onComplete();
						if(isLoadingMore) setLoading(false);
					})
					.catch((error) => {
						console.log('error');
					});
		};


		if (typeof props.renderProductCards === 'function' && Object.entries(results).length) _doProducts();

		if ((results.length === 30 || !hasNextPage || results.length === context.questionnaire.max_results_per_type)) setShowLoadMore(false);
	}

	}, [results]);

	useEffect(() => {
		if (cards.length && !waitForCallback) {
			if (typeof props.onRenderComplete === 'function') props.onRenderComplete();
		}
	}, [cards, waitForCallback]);


	const RowTag =		context.params.customStyling.results && context.params.customStyling.results.rowTagEl
		? context.params.customStyling.results.rowTagEl
		: 'div';

	return (
		<div ref={ forwardedRef } className="irjs__results--section" id={ id }>
			<ResultGroupHeading text={ type } nonLatin={ nonLatinType } type={ type.toLowerCase() } />
			{typeof props.renderProductCards === 'function' && waitForCallback && (
				<LoadingDots className={ clsx('fade', waitForCallback && 'in') } showText={ false } />
			)}
			{!waitForCallback && (
				<>
					<RowTag className={ clsx('irjs__results--row', context.getCustomStyling('results.rowClass')) }>
						{typeof props.renderProductCards === 'function' && cards.map((card, idx) => <CustomResultCard { ...card } onClick={(e) => {
							// e.preventDefault();

							let capture = pick(['variant_id','variant_year','product_name','price','format_ml','landing_url'], card.product);

							PreferabliAnalytics.track('guidedrec product clickthru', {
								onlyMp: true,
								product_id: card.product.value,
					            ...capture,
					        });

						}} />

						)}

						{!props.renderProductCards
							&& results.map((result) => {
								const { lookups, product } = result;

								return (
									<ProductCard
										compact={ isCompact }
										key={ result.id }
										hasMultipleLookups={ lookups.length > 1 }
										lookups={ lookups }
									/>
								);
							})}
					</RowTag>
					{showLoadMore && (
						<button
							type="button"
							className={ clsx('irjs__btn', 'irjs__btn--solid', 'mx-auto', context.getCustomStyling('basicButton')) }
							data-offset={ results.length }
							data-type={ nonLatinType.toLowerCase() }
							onClick={ handleLoadMore }
							disabled={ isLoadingMore }
						>
							{isLoadingMore ? ( <LoadingDots className={ clsx('fade', isLoadingMore && 'in') } showText={ false } color="#fff" /> ) : ( <span>{ LokaliseLanguage.getSlugTranslation({slug:'result.showmorebtn'}) }</span> ) }
						</button>
					)}
				</>
			)}
		</div>
	);
};

ResultsGrouping.propTypes = {
	id: PropTypes.string,
	type: PropTypes.string,
	nonLatinType: PropTypes.string,
	results: PropTypes.oneOfType([PropTypes.shape(), PropTypes.arrayOf(PropTypes.shape())]),
	hasNextPage: PropTypes.bool,
	renderProductCards: PropTypes.func,
	onRenderComplete: PropTypes.func,
	loadMore: PropTypes.func,
	onComplete: PropTypes.func,
	forwardedRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
	inViewport: PropTypes.bool,
};
ResultsGrouping.defaultProps = {
	id: '',
	type: '',
	nonLatinType: '',
	results: null,
	hasNextPage: false,
	renderProductCards: null,
	onRenderComplete: null,
	loadMore: () => {},
	onComplete: () => {},
	forwardedRef: null,
	inViewport: false,
};

const ResultGrouping = handleViewport(ResultsGrouping, { rootMargin: '-200px 0px' });

const ResultsSection = (props) => {
	const loadingState = useRef(true);
	const [updated, force] = useState();

	useEffect(() => {
		loadingState.current = false;
	}, [updated]);

	return (
		<ResultGrouping
			onLeaveViewport={ (scrollDir) => {
				if (loadingState.current) return;
				props.onScrollChange(scrollDir, props.id);
			} }
			onComplete={ () => {
				force();
				props.onComplete();
			} }
			{ ...props }
		/>
	);
};

ResultsSection.propTypes = {
	id: PropTypes.string.isRequired,
	onScrollChange: PropTypes.func,
	activeSection: PropTypes.string,
	onComplete: PropTypes.func,
};
ResultsSection.defaultProps = {
	onScrollChange: () => {},
	activeSection: '',
	onComplete: () => {},
};

export default ResultsSection;
