const sub_categories = [
'red',
'white',
'rose',
'sparkling',
'fortified',
'bourbon',
'flavored_whiskey',
'rye',
'scotch',
'agave_spirit',
'mezcal',
'ale',
'gluten_free_beer',
'non_alcoholic_beer',
'tequila',
'whiskey',
'lager',
'flavored_alcoholic_beverage',
'non_alcoholic_drink',
'energy_drink',
'canabis_based_drink',
'dairy_cheese',
'non_dairy_cheese'
];

const types = [
'red',
'white',
'rose',
'sparkling',
'fortified',
'american_whiskey',
'blended_grain_scotch_whisky',
'blended_grain_whiskey',
'blended_malt_scotch_whisky',
'blended_malt_whiskey',
'blended_scotch_whisky',
'blended_whiskey',
'bourbon_whiskey',
'canadian_whisky',
'flavored_whiskey',
'irish_whiskey',
'japanese_whisky',
'rye_whiskey',
'single_grain_scotch_whisky',
'single_malt_scotch_whisky',
'single_malt_whiskey',
'tennessee_whiskey',
'agave_spirit',
'mezcal',
'amber_ale',
'belgian_style_ale',
'belgian_style_strong_ale',
'blonde_ale',
'british_bitter',
'hazy_india_pale_ale',
'india_pale_ale',
'milk_pastry_cream_stout',
'pale_ale',
'porter',
'scotch_ale_barley_wine',
'stout',
'amber_lager',
'dark_lager',
'hoppy_lager',
'lager',
'pilsner',
'barrel_aged_ale',
'barrel_aged_sour',
'coffee_or_chocolate_ale',
'fruit_ale',
'gluten_free_lager',
'non_alcoholic_ale',
'other_flavored_ale',
'smoked_ale',
'sour',
'wheat_beer',
'brown_ale',
'tequila',
'unaged_whiskey',
'corn_whiskey',
'gluten_free_ale',
'non_alcoholic_lager',
'baltic_porter',
'barrel_aged_lager',
'chocolate_or_coffee_lager',
'fruit_lager',
'other_flavored_lager',
'smoked_lager',
'hard_soda',
'hard_kombucha',
'hard_lemonade',
'sparkling_hard_lemonade',
'hard_smoothie',
'hard_seltzer',
'hard_sweet_tea',
'sparkling_hard_sweet_tea',
'wine_spritzer',
'sparkling_non_alcoholic_beverage',
'still_non_alcoholic_beverage',
'cocktail',
'sparkling_cocktail',
'sake_cocktail',
'malt_beverage',
'sparkling_malt_beverage',
'energy_drink',
'cannabis_based_product',
'wine_based_beverage',
'hard_punch',
'sparkling_hard_punch',
'blue_veined_cheese',
'blue_veined_vegan_cheese',
'fresh_cheese',
'fresh_vegan_cheese',
'hybrid_cheese',
'pressed_or_hard_cheese',
'pressed_or_hard_vegan_cheese',
'processed_cheese',
'processed_vegan_cheese',
'soft_ripened_cheese',
'soft_ripened_vegan_cheese',
'washed_rind_cheese',
'washed_rind_vegan_cheese',
'ale',
'bourbon',
'canabis_based_drink',
'dairy_cheese',
'flavored_alcoholic_beverage',
'gluten_free_beer',
'non_alcoholic_beer',
'non_alcoholic_drink',
'non_dairy_cheese',
'rye',
'scotch',
'whiskey',
];

let recongized_types = {};

export default Object.assign(recongized_types, {
	SubCategories: sub_categories,
	All: types,
});
